let offcanvas = document.querySelectorAll('[data-bs-toggle="offcanvas"]');
if(offcanvas.length) {
    import(/* webpackChunkName: "bs-offcanvas" */ 'bootstrap/js/dist/offcanvas')
    .then(() => {
        console.log('loaded bs offcanvas')
    })
}

let tooltips = document.querySelectorAll('[data-bs-toggle="tooltip"]');
if(tooltips.length) {
    import(/* webpackChunkName: "bs-tooltip" */ 'bootstrap/js/dist/tooltip')
    .then(() => {
        for(var i = 0; i < tooltips.length; i++)
        {
            console.log('loaded bs tooltips')
            let tooltip = tooltips.item(i);
            $(tooltip).tooltip();
        } 
    });
}

let modals = document.querySelectorAll('[data-bs-toggle="modal"]');
if(modals.length) {
    import(/* webpackChunkName: "bs-modal" */ 'bootstrap/js/dist/modal')
    .then(() => {
        console.log('loaded bs modal')
    });
}

let dropdown = document.querySelectorAll('[data-bs-toggle="dropdown"]');
if(dropdown.length) {
    import(/* webpackChunkName: "bs-dropdown" */ 'bootstrap/js/dist/dropdown')
    .then(() => {
        console.log('loaded bs dropdown')
    })
}

let tabs = document.querySelectorAll('[data-bs-toggle="tab"]');
if(tabs.length) {
    import(/* webpackChunkName: "bs-tab" */ 'bootstrap/js/dist/tab')
    .then(() => {
        console.log('loaded bs tabs')
    })
}

let alerts = document.querySelectorAll('[data-bs-dismiss="alert"]');
if(alerts.length) {
    import(/* webpackChunkName: "bs-alert" */ 'bootstrap/js/dist/alert')
    .then(() => {
        console.log('alerts loaded')
    })
}

let collapses = document.querySelectorAll('.collapse');
if(collapses.length) {
    import(/* webpackChunkName: "bs-collapse" */ 'bootstrap/js/dist/collapse')
    .then(() => {
        console.log('collapse loaded')
    })
}

let toasts = document.querySelectorAll('.toast');
if(toasts.length) {
    import(/* webpackChunkName: "bs-toast" */ 'bootstrap/js/dist/toast')
    .then((Toast) => {
        for(var i = 0; i < toasts.length; i++)
        {
            let toast = new Toast.default(toasts.item(i));
            toast.show();
        } 
    })
}

let carousel = document.querySelectorAll('.carousel');
if(carousel.length) {
    import(/* webpackChunkName: "bs-carousel" */ 'bootstrap/js/dist/carousel')
    .then(() => {
        console.log('collapse loaded')
    })
}

// let items = document.querySelectorAll('.multi-carousel.carousel .carousel-item')
// if(items.length) {
//     items.forEach((el) => {
//         const minPerSlide = 4
//         let next = el.nextElementSibling
//         for (var i = 1; i < minPerSlide; i++) {
//             if (!next) {
//                 // wrap carousel by using first child
//                 next = items[0]
//             }
//             let cloneChild = next.cloneNode(true)
//             el.appendChild(cloneChild.children[0])
//             next = next.nextElementSibling
//         }
//     })
// }
